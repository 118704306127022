import React from 'react'

import Layout from 'components/Layout/Layout';
import Home from 'components/Home/Home';
import SEO from 'components/seo';

const IndexPage = () => (
  <Layout displayProgressBar={false}>
    <SEO title="Home" />
    <Home />
  </Layout>
)

export default IndexPage
