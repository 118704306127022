import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { Row, Col } from 'reactstrap';

import Wordmark from 'images/wordmark.inline.svg';

const StyledWordmark = styled(Wordmark)`
  width: 129px;
  height: 39px;
  align-self: center;
  margin-top: 0;
  margin-bottom: 32px;

  ${breakpoint('lg')` 
    margin-top: 48px;
  `}
`;

const Rocket = styled.div`
  display: block;
  position: relative;
  height: 230px;
  width: 130px;

  #rocket {
    position: absolute;
    top: 0;
    left: 0;
  }

  #fire {
    position: absolute;
    top: 178px;
    left: 48px;
    height: 40px;
    animation: mymove 200ms infinite;
  }

  @keyframes mymove {
    0%   {height: 35px;}
    50%  {height: 60px;}
    100% {height: 35px;}
  }
  #line1{
    width: 2px;
    height: 5px;
    background-color: #888;
    position: absolute;
    left: 5%;
    animation: godown 1s infinite;
  }
  #line2{
    width: 1px;
    height: 7px;
    background-color: #888;
    position: absolute;
    left: 25%;
    animation: godown2 .85s infinite;
  }
  #line3{
    width: 1px;
    height: 7px;
    background-color: #888;
    position: absolute;
    left: 50%;
    animation: godown 1.1s infinite;
  }
  #line4{
    width: 2px;
    height: 5px;
    background-color: #888;
    position: absolute;
    left: 75%;
    animation: godown2 .95s infinite;
  }
  #line5{
    width: 1px;
    height: 7px;
    background-color: #888;
    position: absolute;
    left: 95%;
    animation: godown 1.3s infinite;
  }
  @keyframes godown{
    0% {bottom: 100%}
    50% {bottom: 50%}
    100% {bottom: -10%}
  }
  @keyframes godown2{
    0% {bottom: 120%}
    50% {bottom: 70%}
    100% {bottom: 15%}
  }
`;

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 32px);
`;


const RocketContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const Heading = styled.h2`
  ${props => props.theme.main.fonts.application.h2};
  margin-top: 43px;
  margin-bottom: 0;
  text-align: center;
`;

const Description = styled.p`
  ${props => props.theme.main.fonts.body.normal};
  color: ${props => props.theme.main.colors.grey.base};
  margin-top: 16px;
  text-align: center;
  opacity: 0;
  transition: opacity 500ms;
  ${props => props.display === 'true' && css`
    opacity: 1;
  `}
`;


export default React.memo(() => {
  const [displayDescription, setDisplayDescription] = useState(false);
  const [description, setDescription] = useState('Almost done. Thanks for waiting.');

  useEffect(() => {
    setTimeout(() => setDisplayDescription(true), 10000);
    setTimeout(() => setDescription('This is taking longer than usual. Please close this page and try again.'), 60000);
  }, []);

  return (
    <Row>
      <StyledCol md={{size: 6, offset: 3}} sm={{size: 8, offset: 2}}>
        <div>
          <StyledWordmark />
        </div>
        <RocketContainer>
          <Rocket>
            <div>
              <svg width="34" height="53" viewBox="0 0 34 53" fill="none" xmlns="http://www.w3.org/2000/svg" id="fire">
              <path d="M30.7816 0.166354C32.8468 3.7627 33.9993 7.9992 33.9993 12.4332C33.9513 22.6312 21.37 47.6338 19.9292 50.3925C19.3528 51.4765 18.2005 52.166 16.952 52.1659C15.7035 52.1657 14.5505 51.4765 13.9265 50.3431C12.5341 47.6337 0.0480129 22.6308 0 12.4335C1.3841e-07 7.99986 1.15247 3.76302 3.21735 0.166351L30.7816 0.166354Z" fill="#FFE470"/>
              <path d="M16.9527 0.166204L30.7823 0.165878C32.8475 3.76222 34 7.99873 34 12.4328C33.952 22.6307 21.3707 47.6334 19.9299 50.392C19.3535 51.476 18.2012 52.1656 16.9527 52.1654L16.9527 0.166204Z" fill="#FFD400"/>
              </svg>
            </div>
            <div>
              <svg width="130" height="201" viewBox="0 0 130 201" fill="none" xmlns="http://www.w3.org/2000/svg" id="rocket">
              <path d="M67.9869 181.537L68.0361 195.319C67.9868 196.244 67.5905 197.12 66.996 197.705C66.352 198.338 65.4605 198.727 64.519 198.776L64.4695 198.728C62.5377 198.679 61.0019 197.169 61.002 195.319L61.0516 181.536C61.002 179.637 62.5377 178.127 64.4696 178.176L64.5192 178.127C66.4511 178.079 68.0361 179.638 67.9869 181.537Z" fill="#FFD400"/>
              <path d="M64.5196 178.128C66.4513 178.079 68.0365 179.638 67.9872 181.537L68.0364 195.319C67.987 196.244 67.5908 197.121 66.9963 197.705C66.3523 198.338 65.4608 198.728 64.5192 198.777L64.4697 198.728L64.4699 178.176L64.5196 178.128Z" fill="#FDBF00"/>
              <path d="M49.6876 170.691H79.5442L81.6768 180.442L69.5997 181.921C66.2895 182.326 62.9423 182.326 59.6321 181.921L47.555 180.442L49.6876 170.691Z" fill="#49433D"/>
              <path d="M58.5073 172.462L29.7844 175.051C28.2446 175.222 26.8197 174.322 26.6197 173.051C25.6413 166.838 29.4896 160.825 36.2468 158.007L59.133 152.455L58.5073 172.462Z" fill="#CA1905"/>
              <path d="M71.091 172.462L99.8139 175.051C101.354 175.222 102.779 174.322 102.979 173.051C103.957 166.838 100.109 160.825 93.3516 158.007L70.4654 152.455L71.091 172.462Z" fill="#CA1905"/>
              <path d="M60.66 133.718L61.4499 85.7317L2.12973 134.019C0.960695 135.106 0.241317 136.968 0.207984 138.993L0.00125808 151.552C-0.0679732 155.758 2.73188 158.841 5.48638 157.592L60.66 133.718Z" fill="#CA1905"/>
              <path d="M69.34 133.718L68.5501 85.7318L127.87 134.019C129.039 135.106 129.759 136.968 129.792 138.993L129.999 151.552C130.068 155.758 127.268 158.841 124.514 157.592L69.34 133.718Z" fill="#CA1905"/>
              <path d="M84.0791 40.7359L82.2527 106.042C81.9462 117 82.2526 138.958 82.2526 150.066L82.1787 170.198C81.1492 176.531 69.55 176.843 64.8731 176.717C60.1962 176.59 47.6936 176.578 47.0196 170.199L47.0203 142.551C47.0203 131.443 46.7138 117 47.0203 106.042L47.0203 40.7359C47.2699 31.8083 48.3357 20.618 51.4702 12.7274C54.1697 5.93246 59.9731 0.0986433 64.8719 0.231055C69.7708 0.363466 78.1397 7.94526 80.4553 14.8758C83.1447 22.924 84.3288 31.8083 84.0791 40.7359Z" fill="#FFFEF4"/>
              <path d="M64.7799 0.153991C63.0557 0.107388 62.7041 0.958386 61.2493 2.23672C63.832 4.7187 69.8966 8.93813 71.4132 13.4776C74.1022 21.5254 75.2865 30.4099 75.0366 39.3377L73.2103 104.643C72.9039 115.602 73.2103 126.625 72.2261 137.732L70.8138 166.891C70.1589 170.919 62.3467 174.089 59.8372 175.916C61.2495 176.492 69.0173 176.377 70.8138 176.208C76.7851 175.39 82.5489 175.547 82.5489 166.891V137.255C82.5489 126.625 82.2424 115.775 82.5489 104.816L84.3756 39.5103C84.6252 30.5827 83.0044 21.5256 80.3156 13.4776C77.9997 6.54725 69.6788 0.286403 64.7799 0.153991Z" fill="#F1F1F1"/>
              <ellipse cx="65.8317" cy="58.936" rx="12.7109" ry="12.4964" fill="#776E64"/>
              <ellipse cx="65.8326" cy="58.936" rx="9.88629" ry="9.71941" fill="#40BBC6"/>
              <path d="M66.6231 49.9043C69.4331 49.5096 70.4701 45.4644 70.6374 43.4911C70.8047 45.4644 71.8417 49.5096 74.6517 49.9043C73.3136 50.562 70.6374 52.4696 70.6374 54.8375C70.6374 52.4696 67.9612 50.562 66.6231 49.9043Z" fill="#FFF4CB"/>
              </svg>
            </div>
            <div id="line1"></div>
            <div id="line2"></div>
            <div id="line3"></div>
            <div id="line4"></div>
            <div id="line5"></div>
          </Rocket>   
          <Heading>Launching...</Heading>
          <Description display={displayDescription.toString()}>{description}</Description>
        </RocketContainer> 
    </StyledCol>
  </Row>);
});